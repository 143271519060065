import React, { useContext, useEffect } from 'react';
import styles from './Methods.module.css';
import PaymentContext from '../../context/Payment/Context';
import ProductContext from '../../context/Product/Context';
import MethodsFlow from './components/MethodsFlow';
import totalPriceCarts from '../../hooks/totalCarts';
import SocialProof from '../SocialProof';

const Methods = () => {
  const paymentContext = useContext(PaymentContext);
  const productContext = useContext(ProductContext);
  const { order, carts } = productContext;
  const totalPrice = totalPriceCarts(carts, false, order);
  const { hasMonthsFunction } = paymentContext;

  const $body = document.querySelector('body');
  const $html = document.querySelector('html');
  const scrollPosition = 0;

  useEffect(() => {
    if (totalPrice < 300) {
      hasMonthsFunction(false);
    } else {
      hasMonthsFunction(true);
    }
  }, [totalPrice]);

  useEffect(() => {
    $html.style.removeProperty('height');
    $body.style.removeProperty('overflow');
    $body.style.removeProperty('position');
    $body.style.removeProperty('top');
    $body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition);
  }, []);

  return (
    <div className={styles.container}>
      <MethodsFlow totalPrice={totalPrice} />
      <div className={styles.containerPayment}>
        <SocialProof />
      </div>
    </div>
  );
};

export default Methods;
