import React, { useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import ProductContext from '../Context';
import ProductReducer from '../Reducer';
import {
  ORDER,
  PRODUCTS,
  CARTS,
  SET_COUPONS,
  SET_COUPONS_CODE,
  SET_PRODUCTS_DISCOUNT,
} from '../types';

const ProductState = ({ children }) => {
  const initialstate = {
    productsWithDiscount: [],
    products: [],
    carts: [],
    coupons: [],
    couponsCode: [],
    order: null,
  };

  const [state, dispatch] = useReducer(ProductReducer, initialstate);

  const setProducts = (data) => {
    localStorage.setItem('products', JSON.stringify(data));
    dispatch({
      type: PRODUCTS,
      payload: data,
    });
  };

  const setCarts = (data) => {
    localStorage.setItem('carts', JSON.stringify(data));
    dispatch({
      type: CARTS,
      payload: data,
    });
  };

  const setCoupons = (coupons) => {
    dispatch({
      type: SET_COUPONS,
      payload: coupons,
    });
  };

  const setCouponsCode = (coupons) => {
    dispatch({
      type: SET_COUPONS_CODE,
      payload: coupons,
    });
  };

  const setProductsWithDiscount = (products) => {
    dispatch({
      type: SET_PRODUCTS_DISCOUNT,
      payload: products,
    });
  };

  const setOrder = (data) => {
    localStorage.setItem('order', JSON.stringify(data));
    dispatch({
      type: ORDER,
      payload: data,
    });
  };

  const contextValue = useMemo(() => {
    return {
      products: state.products,
      carts: state.carts,
      order: state.order,
      coupons: state.coupons,
      couponsCode: state.couponsCode,
      productsWithDiscount: state.productsWithDiscount,
      setOrder,
      setCarts,
      setProducts,
      setCoupons,
      setCouponsCode,
      setProductsWithDiscount,
    };
  }, [
    state.products,
    state.order,
    state.carts,
    state.coupons,
    state.couponsCode,
    state.productsWithDiscount,
    setOrder,
    setCarts,
    setProducts,
    setCoupons,
    setCouponsCode,
    setProductsWithDiscount,
  ]);

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductState;

ProductState.propTypes = {
  children: PropTypes.node.isRequired,
};
