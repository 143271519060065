import convertPrice from './convertPrice';
/**
 * Return total price of all carts
 *
 * @param {array} carts
 * @param {boolean} isToShow when is true the function return with commas the price
 * @param {array} order when is true the function return with commas the price
 * @returns {string} final price carts
 */
function totalPriceCarts(carts, isToShow, order = null) {
  let totalPrice = '';

  carts.forEach((cartsArr) => {
    const { centAmount } = cartsArr.totalPrice;
    totalPrice = +centAmount / 100 + +totalPrice;
  });

  let custom = null;
  if (carts[0].custom) custom = carts[0].custom;
  if (order && order.custom) custom = order.custom;

  if (custom) {
    const { fields } = custom;
    if (fields) {
      const { amountToBePaid = null, applyRetention = false, billingAmount = null } = fields;

      if (amountToBePaid) {
        totalPrice = +amountToBePaid;
      }
      if (applyRetention && billingAmount && billingAmount > 0) {
        totalPrice -= +billingAmount;
      }
    }
  }
  return isToShow ? convertPrice(Number(totalPrice).toFixed(2)) : Number(totalPrice);
}

export default totalPriceCarts;
