import React from 'react';
import ReactDOM from 'react-dom';
import PaymentContext from './context/Payment/State';
import GlobalContext from './context/Global/State';
import ThankYouContext from './context/ThankYou/State';
import ProductContext from './context/Product/State';
import './styles/fonts.module.css';
import './styles/global.module.css';
import '@futura-library/ui-library/dist/library.min.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <GlobalContext>
      <PaymentContext>
        <ThankYouContext>
          <ProductContext>
            <App />
          </ProductContext>
        </ThankYouContext>
      </PaymentContext>
    </GlobalContext>
  </React.StrictMode>,
  document.getElementById('root'),
);
