import {
  SHOW_CLOSE_ADDRESS_MODAL,
  CREATE_ADDRESS,
  HAS_ADDRESS,
  UPDATE_DASHBOARD,
  EDIT_ADDRESS,
  PAYMENT_TYPE,
  SHOWHIDE_PRODUCTS_MOBILE,
  SHOW_CLOSE_CARD_MODAL,
  TOTAL_PRICE_CARTS,
  HAS_MONTHS,
  SELECT_INSTALMENTS,
  SET_MONTHS,
  SET_INSTALLMENTS_TAX,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case SHOW_CLOSE_ADDRESS_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case CREATE_ADDRESS:
      return {
        ...state,
        createAddress: false,
        address: action.payload,
      };
    case HAS_ADDRESS:
      return {
        ...state,
        hasAddress: action.payload,
      };
    case UPDATE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case EDIT_ADDRESS:
      return {
        ...state,
        editAddress: action.payload,
      };
    case PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
      };
    case SHOWHIDE_PRODUCTS_MOBILE:
      return {
        ...state,
        productsMobile: action.payload,
      };
    case SHOW_CLOSE_CARD_MODAL:
      return {
        ...state,
        cardModal: action.payload,
      };
    case TOTAL_PRICE_CARTS:
      return {
        ...state,
        totalPriceCarts: action.payload,
      };
    case HAS_MONTHS:
      return {
        ...state,
        hasMonths: action.payload,
      };
    case SELECT_INSTALMENTS:
      return {
        ...state,
        paymentInstallments: action.payload,
      };
    case SET_MONTHS:
      return {
        ...state,
        months: action.payload,
      };
    case SET_INSTALLMENTS_TAX:
      return {
        ...state,
        installmentsTax: action.payload,
      };
    default:
      return state;
  }
};
