export const USER_INFO = 'USER_INFO';
export const DATA_OXXO = 'DATA_OXXO';
export const DATA_EFECTY = 'DATA_EFECTY';
export const DATA_SPEI = 'DATA_SPEI';
export const DATA_CARD = 'DATA_CARD';
export const DATA_PAYPAL = 'DATA_PAYPAL';
export const DATA_PSE = 'DATA_PSE';
export const DATA_EFECTIVO = 'DATA_EFECTIVO';
export const DATA_CULQI = 'DATA_CULQI';
export const DATA_CULQI_CUOTEALO = 'DATA_CULQI_CUOTEALO';
export const DATA_CLOSE = 'DATA_CLOSE';
