import axios from 'axios';
import PropTypes from 'prop-types';

const axiosOrder = (data) => {
  const {
    REACT_APP_CHECKOUT_API_URL,
    REACT_APP_CHECKOUT_AUTH_USERNAME,
    REACT_APP_CHECKOUT_AUTH_PASSWORD,
  } = process.env;

  const options = {
    auth: {
      username: REACT_APP_CHECKOUT_AUTH_USERNAME,
      password: REACT_APP_CHECKOUT_AUTH_PASSWORD,
    },
  };

  return axios.post(
    `${REACT_APP_CHECKOUT_API_URL}/futura/free-orders`,
    data,
    options,
  );
};

axiosOrder.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string.isRequired }),
};

export default axiosOrder;
