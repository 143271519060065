import React from 'react';
import styles from './PaymentMethod.module.css';
import Methods from '../../components/Methods';
// import MultiplePayment from '../Summary/components/MultiplePayments';
import { Footer } from '../../components/Footer';

const PaymentMethod = () => {
  return (
    <div className={styles.container}>
      {/* <MultiplePayment /> */}
      <Methods />
      <Footer />
    </div>
  );
};

export default PaymentMethod;
