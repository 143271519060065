import React from 'react';
import styles from '../../MethodsFlow.module.css';
import CulqiForm from './components/CulqiForm';
import { culqi } from '../../../../../../assets/images/Global';

const FlowCulqi = () => {
  return (
    <>
      <div className={styles.titleContainer}>
        <p className={styles.title}>Paga a través de Culqi</p>
        <img
          className={styles.logo}
          width={54}
          height={18}
          src={culqi}
          alt="Culqi Logo"
        />
      </div>
      <div className={styles.container}>
        <CulqiForm />
      </div>
    </>
  );
};

export default FlowCulqi;
