/* eslint-disable */
import React from 'react';
import styles from './FuturaSpinner.module.css';

export const SpinnerThemes = {
  size: {
    s: 'SpinnerSm',
    m: 'SpinnerMed',
    l: 'SpinnerL',
    xl: 'SpinnerXL'
  },
  imageUrls: {
    spinnerInherit: 'https://ui-library-assets.s3.amazonaws.com/images/spinner-inherit.png',
    spinnerInverted: 'https://ui-library-assets.s3.amazonaws.com/images/spinner-inverted.png'
  }
}

/**
 * Futura Spinner component
 * @param {*} param0 props
 * @param {String} size size of spinner (options: s, m, l, xl)
 * @param {String} variant spinner variant, inverted or inherit => white or dark
 * @param {Boolean} inBtn flag for giving styles to spinner inside button
 * @param {Object} children children prop to render children of the component
 * @returns {*} JSX component
 */
const FuturaSpinner = ({
  size = 'xl', variant = 'inverted', inBtn = false, children
}) => {
  const classes = [styles.FuturaSpinner]
  const spinnerClass = [SpinnerThemes.size[size] || SpinnerThemes.size.xl]
  if (inBtn) {
    classes.push(styles.SpinnerButton)
  }
  return (
    <div className={classes.join(' ')}>
      <img
        src={variant === 'inverted' ? SpinnerThemes.imageUrls.spinnerInverted : SpinnerThemes.imageUrls.spinnerInherit}
        alt="spinner-animation"
        className={spinnerClass.join(' ')}
      />
      {children}
    </div>
  )
}

export default FuturaSpinner
