/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@futura-library/ui-library';
import { mercadoPago, mercadoPagoMobile, priceCheck } from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import axiosPost from '../../../../../../methods/cashPayments/mx/axiosPost';
import ThankYouContext from '../../../../../../context/ThankYou/Context';
import PaymentContext from '../../../../../../context/Payment/Context';
import ProductContext from '../../../../../../context/Product/Context';
import GlobalContext from '../../../../../../context/Global/Context';
import organizeDataPayments from '../../../../../../methods/organizeDataPayments';
import AlertModal from '../../../../../AlertModal';
import { axiosPutRecalculateCarts } from '../../../../../../methods/products/axiosPut';
import { areThereCartsWithoutEnoughStock } from '../../../../../../utils/stock';
import totalPriceCarts from '../../../../../../hooks/totalCarts';
import sendFreshpaintEvent from '../../../../../../utils/sendFreshpaintEvent';
import { availableCountries } from '../../../../../../utils/locale';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';

const FlowEfectivo = () => {
  const paymentContext = useContext(PaymentContext);
  const thankYouContext = useContext(ThankYouContext);
  const productContext = useContext(ProductContext);
  const globalContext = useContext(GlobalContext);
  const [loadingEfectivoButton, setLoadingEfectivoButton] = useState(false);
  const [preventModal, setPreventModal] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { titleEfectivo, descriptionEfectivo, stepsEfectivo, buttonEfectivo } =
    data.efectivo;
  const {
   
    name,
    email,
    customerId,
    publicKey,
    origin,
    setEmptyAddressFunction,
  } = globalContext;
  var country = 'pe';
  const { setDataEfectivo } = thankYouContext;
  const { hasAddress } = paymentContext;
  const {
    carts: cartsProducts,
    couponsCode,
    productsWithDiscount,
    order,
  } = productContext;
  let dataToEfectivo = organizeDataPayments();
  const totalPrice = totalPriceCarts(cartsProducts, false, order);
  const carts = new URLSearchParams(window.location.search).getAll('cart');
  const buttonUrl = new URLSearchParams(window.location.search).get(
    'redirectUrl'
  );
  const outOfStockModal = {
    title: '¡Lo sentimos!',
    description:
      'Uno de los productos de tu carrito ya no está disponible. Regresa a la tienda y vuelve a hacer tu selección.',
    imgAlert: priceCheck,
    urlRedirect: buttonUrl,
    discardLink: buttonUrl,
  };

  const dataToFreshpaint = {
    customer_id: customerId,
    distinct_id: customerId,
    product_id: cartsProducts[0]?.lineItems[0]?.id,
    cart_id: cartsProducts[0]?.id,
    product_quantity: cartsProducts[0]?.lineItems.length,
    public_key: publicKey,
    country_ops: country,
    currency: availableCountries[country.toLocaleLowerCase()].currency,
    payment_method: 'pagoEfectivo',
    product_category: 'appointment',
    product: `appointment-${country.toLocaleLowerCase()}`,
    payment_type: 'Complete',
    platform: 'checkout_futura',
    transaction_amount: cartsProducts[0]?.totalPrice.centAmount / 100,
    name,
    email,
  };

  const isMobile = useMediaQuery('(max-width: 1024px)');

  useEffect(() => {
    if (productsWithDiscount.length !== 0) {
      const productsArray = [];

      productsWithDiscount.forEach((product) => {
        const { images } = product.variant;
        const { centAmount } = product.price.value;
        const productsConvert = {
          id: product.id,
          productId: product.productId,
          name: 'es-CO',
          price:
            product?.discountedPricePerQuantity[0]?.discountedPrice?.value
              .centAmount,
          quantity: product.quantity,
          totalPrice: centAmount * product.quantity,
          images,
        };
        productsArray.push(productsConvert);
      });

      dataToEfectivo.cartData.totalLineItemQuantity = productsArray.length;
      dataToEfectivo.cartData.lineItems = productsArray;
    }
  }, [couponsCode]);

  const getEfectivoReference = () => {

    (async () => {
      try {
        const { data: upToDateCartData } = await axiosPutRecalculateCarts(
          carts
        );

        if (areThereCartsWithoutEnoughStock(upToDateCartData.carts)) {
          setPreventModal(true);
        } else {
          setPreventModal(false);

          dataToEfectivo.country = 'Peru';
          dataToEfectivo.discountPrice = couponsCode.length > 0 ? true : false;
          dataToEfectivo.paymentMethodType = 'pagoEfectivo';
          dataToEfectivo.transactionType = 'product';
          dataToEfectivo.productKeyName =
            cartsProducts[0]?.lineItems[0]?.productKey;
          dataToEfectivo.origin = origin;
          setLoadingEfectivoButton(true);
          axiosPost(dataToEfectivo)
            .then((res) => {
              dataToFreshpaint.attempted_status = true;
              if (origin === 'appointmentv2') {
                sendFreshpaintEvent(
                  'New Checkout - Payment Attempted',
                  dataToFreshpaint
                );
              }
            
              setDataEfectivo(res.data);
              setLoadingEfectivoButton(false);
              window.location.replace(res.data.mercadoPagoLink);
            })
            .catch(() => {
              dataToFreshpaint.attempted_status = false;
              if (origin === 'appointmentv2') {
                sendFreshpaintEvent(
                  'New Checkout - Payment Attempted',
                  dataToFreshpaint
                );
              }
              setLoadingEfectivoButton(false);
            });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setPreventModal(false);
      }
    })();
  };

  useEffect(() => {
    if (totalPrice >  10000) {
      setDisableButton(true);
    }
  }, [totalPrice]);

  return (
    <>
      {preventModal && (
        <AlertModal
          data={outOfStockModal}
          modal={false}
          discardOption={false}
        />
      )}
      <div className={styles.titleContainer}>
        <p className={styles.title}>{titleEfectivo}</p>
        {!isMobile ? (
          <img
          className={styles.logo}
          width={91}
          src={mercadoPago}
          alt="Efectivo Logo"
          />
        ):(
          <img
            className={styles.logo}
            width={33.5}
            src={mercadoPagoMobile}
            alt="Efectivo Logo"
          />
        )}
       
      </div>
      <div className={styles.container}>
        <p className={styles.text}>{descriptionEfectivo}</p>
        <ul className={styles.list}>
          {stepsEfectivo.map((step, index) => (
            <li key={step} className={styles.text}>
              <div className={styles.circle}>{index + 1}</div>
              <div className={styles.stepText}>{step}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.button}>
        <Button
          id="Check_Flow_GetReferenceOxxo"
          color="blue"
          disabled={disableButton}
          fullWidth
          isButton
          label={buttonEfectivo}
          rel="noopener noreferrer"
          size="medium"
          variant="filled"
          loading={loadingEfectivoButton}
          onClick={() => getEfectivoReference()}
        />
      </div>
    </>
  );
};

export default FlowEfectivo;
