import React, { useContext } from 'react';
import { Banner } from '@futura-library/ui-library';
import {
  cardVisa,
  mastercard,
  amex,
} from '../../../../../../assets/images/Global';
import styles from '../../MethodsFlow.module.css';
import data from '../../data';
import CardForm from './components/CardForm';
import PaymentContext from '../../../../../../context/Payment/Context';
import GlobalContext from '../../../../../../context/Global/Context';

const FlowCreditCard = () => {
  const paymentContext = useContext(PaymentContext);
  const globalContext = useContext(GlobalContext);
  const { paymentType, hasMonths } = paymentContext;
  const { country } = globalContext;
  const { titleCard, titleCardMonth } = data.card;

  return (
    <>
      <div className={styles.titleContainer}>
        <p className={styles.title}>
          {paymentType === 1 ? titleCard : titleCardMonth}
        </p>
        <div className={styles.logoCards}>
          <img width={24} height={24} src={cardVisa} alt="Visa" />
          <img width={24} height={24} src={mastercard} alt="Mastercard" />
          <img width={24} height={24} src={amex} alt="Amex" />
        </div>
      </div>
      {false && hasMonths && country === 'MX' && (
        <div className={styles.disclaimer}>
          <Banner
            content="Para pagar a meses, ingresa tu número de tarjeta de crédito y se presentarán las opciones de pago a meses en caso de que apliquen."
            fullWidth
            title=""
            variant="default"
          />
        </div>
      )}
      <div className={styles.container}>
        <CardForm />
      </div>
    </>
  );
};

export default FlowCreditCard;
