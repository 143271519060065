export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const SHOW_ADDRESS = 'SHOW_ADDRESS';
export const SHOW_CLOSE_ADDRESS_MODAL = 'SHOW_CLOSE_ADDRESS_MODAL';
export const HAS_ADDRESS = 'HAS_ADDRESS';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const PAYMENT_TYPE = 'PAYMENT_TYPE';
export const SHOWHIDE_PRODUCTS_MOBILE = 'SHOWHIDE_PRODUCTS_MOBILE';
export const SHOW_CLOSE_CARD_MODAL = 'SHOW_CLOSE_CARD_MODAL';
export const HAS_CARD = 'HAS_CARD';
export const TOTAL_PRICE_CARTS = 'TOTAL_PRICE_CARTS';
export const HAS_MONTHS = 'HAS_MONTHS';
export const SELECT_INSTALMENTS = 'SELECT_INSTALMENTS';
export const SET_MONTHS = 'SET_MONTHS';
export const SET_INSTALLMENTS_TAX = 'SET_INSTALLMENTS_TAX';
export const AMOUNT_TO_BE_PAID = 'AMOUNT_TO_BE_PAID';
export const SET_HAS_MULTIPLE_PAYMENTS = 'SET_HAS_MULTIPLE_PAYMENTS';
