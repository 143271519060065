import {
  ORDER,
  PRODUCTS,
  CARTS,
  SET_COUPONS,
  SET_COUPONS_CODE,
  SET_PRODUCTS_DISCOUNT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case CARTS:
      return {
        ...state,
        carts: action.payload,
      };
    case ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case SET_COUPONS:
      return {
        ...state,
        coupons: action.payload,
      };

    case SET_COUPONS_CODE:
      return {
        ...state,
        couponsCode: action.payload,
      };

    case SET_PRODUCTS_DISCOUNT:
      return {
        ...state,
        productsWithDiscount: action.payload,
      };

    default:
      return state;
  }
};
