import React from 'react';
import PropTypes from 'prop-types';
import FuturaSpinner from '../FuturaSpinner';
import styles from './PageLoader.module.css';

/**
 * Page Loader component
 * full screen loader
 * @param {*} param0 props
 * @param {Boolean} scrollTop flag to scroll to top when rendering page loader
 * @returns {*} JSX component
 */
const PageLoader = ({ scrollTop = false }) => {
  if (scrollTop) {
    window.scrollTo(0, 0);
  }
  return (
    <div className={styles.FuturaModal}>
      <FuturaSpinner>
        <div className={styles.PageLoaderLogo} />
      </FuturaSpinner>
    </div>
  );
};

PageLoader.propTypes = {
  scrollTop: PropTypes.bool.isRequired,
};
export default PageLoader;
