import * as Sentry from '@sentry/react';

const { REACT_APP_SENTRY_DNS_URL, REACT_APP_ENVIRONMENT } = process.env;

/**
 * Track errors with Sentry
 */

function sentryInit() {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DNS_URL,
    environment: REACT_APP_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/dev-checkout-futura-82642\.web\.app/, /^https:\/\/checkout\.futuralabs\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default sentryInit;
